import { MaterialQuery } from "../../../graphql/queries/material";
import React, { FC } from "react";
import { Alert, Button, message } from "antd";
import { CopyOutlined } from "@ant-design/icons";
import { useMutation } from "@apollo/react-hooks";
import {
    duplicateMaterialMutation,
    DuplicateMaterialMutation,
    DuplicateMaterialVariables,
} from "../../../graphql/mutations/duplicateMaterial";
import { routes } from "../../../history";
import { useIntl } from "react-intl";

type Props = {
    materialResult?: MaterialQuery;
    materialIdParam: string;
    refetch: () => {};
};

const DuplicateTab: FC<Props> = (props) => {
    const intl = useIntl();
    const [duplicateMaterial] = useMutation<
        DuplicateMaterialMutation,
        DuplicateMaterialVariables
    >(duplicateMaterialMutation.mutation, {
        onCompleted: () => {},
    });

    const handleDuplicationNow = () => {
        duplicateMaterial({
            variables: {
                input: {
                    id: `/materials/${props.materialIdParam}`,
                },
            },
        }).then(($newMaterial) => {
            props.refetch();
            message.success(
                intl.formatMessage({ id: "message.duplicateMaterial.success" }),
                1,
                () => {
                    const $materialId =
                        $newMaterial.data?.duplicateMaterial?.material?._id;

                    if ($materialId !== null) {
                        // @ts-ignore
                        window.location = routes.editMaterial($materialId);
                    } else {
                        // @ts-ignore
                        window.location = routes.organization;
                    }
                }
            );
        });
    };

    // @ts-ignore
    const removeButton = (
        <Button
            type="primary"
            icon={<CopyOutlined />}
            onClick={handleDuplicationNow}
        >
            Duplizieren
        </Button>
    );

    const outflowAlert = (
        <Alert
            style={{ marginBottom: "16px" }}
            type="warning"
            message={intl.formatMessage({
                id: "notice.duplicateMaterialWithFlows.duplicate",
            })}
        />
    );

    // @ts-ignore
    if (props.materialResult?.material?.outflows?.edges?.length > 0) {
        return (
            <>
                {outflowAlert}
                {removeButton}
            </>
        );
    } else {
        return <>{removeButton}</>;
    }
};

export default DuplicateTab;
