import { MaterialQuery } from "../../../graphql/queries/material";
import React, { FC } from "react";
import { Alert, Button, message } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { useMutation } from "@apollo/react-hooks";

import {
    deleteMaterialMutation,
    DeleteMaterialMutation,
    DeleteMaterialMutationVariables,
} from "../../../graphql/mutations/deleteMaterial";
import { routes } from "../../../history";
import { useIntl } from "react-intl";

type Props = {
    materialResult?: MaterialQuery;
    materialIdParam: string;
    refetch: () => {};
};

const DeleteTab: FC<Props> = (props) => {
    const intl = useIntl();
    const [deleteMaterial] = useMutation<
        DeleteMaterialMutation,
        DeleteMaterialMutationVariables
    >(deleteMaterialMutation.mutation, {
        onCompleted: () => {},
    });

    const handleDeleteNow = () => {
        deleteMaterial({
            variables: {
                input: {
                    id: `/materials/${props.materialIdParam}`,
                },
            },
        }).then(() => {
            props.refetch();
            message.success(
                intl.formatMessage({ id: "message.removeMaterial.success" }),
                1,
                () => {
                    // @ts-ignore
                    window.location = routes.organization;
                }
            );
        });
    };

    const hasOutflows = () => {
        // @ts-ignore
        return props.materialResult?.material?.outflows?.edges?.length > 0;
    };

    const hasActiveReservations = () => {
        if (!props.materialResult?.material?.reservedQuantity) return false;
        return props.materialResult?.material?.reservedQuantity > 0;
    };
    const removeButton = (
        <Button
            type="primary"
            danger
            icon={<DeleteOutlined />}
            onClick={handleDeleteNow}
        >
            Löschen
        </Button>
    );

    const hasOutflowAlert = (
        <Alert
            style={{ marginBottom: "16px" }}
            type="error"
            message={intl.formatMessage({
                id: "notice.removeActiveMaterialWithOutflows.remove",
            })}
        />
    );

    const hasActiveReservationAlert = (
        <Alert
            style={{ marginBottom: "16px" }}
            type="error"
            message={intl.formatMessage({
                id: "notice.removeActiveMaterialWithActiveReservations.remove",
            })}
        />
    );

    if (hasActiveReservations()) {
        return (
            <>
                {hasActiveReservationAlert}
                {removeButton}
            </>
        );
    } else if (hasOutflows()) {
        return (
            <>
                {hasOutflowAlert}
                {removeButton}
            </>
        );
    } else {
        return <>{removeButton}</>;
    }
};

export default DeleteTab;
