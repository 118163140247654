import { Select } from "antd";
import React, { FC } from "react";
import { useIntl } from "react-intl";

export type OrderingValue =
    | { title: "ASC" }
    | { title: "DESC" }
    | { updatedAt: "ASC" }
    | { updatedAt: "DESC" }
    | { visibleUntil: "ASC" }
    | { visibleUntil: "DESC" }
    | { publishAt: "ASC" }
    | { publishAt: "DESC" }
    | { aggregatedQuantity: "ASC" }
    | { aggregatedQuantity: "DESC" }
    | { aggregatedReservedQuantity: "DESC" }
    | { aggregatedReservedQuantity: "ASC" };

type Props = {
    orderingValues: Array<String>;
    onChange: (ordering: OrderingValue) => void;
    defaultValue: string;
};

const GetLabelString = (key: String): String => {
    const intl = useIntl();
    switch (key) {
        case "orderMaterial.updated.desc":
            return intl.formatMessage({
                id: "orderMaterial.updated.desc.label",
            });
        case "orderMaterial.updated.asc":
            return intl.formatMessage({
                id: "orderMaterial.updated.asc.label",
            });

        case "orderMaterial.title.desc":
            return intl.formatMessage({
                id: "orderMaterial.title.desc.label",
            });
        case "orderMaterial.title.asc":
            return intl.formatMessage({
                id: "orderMaterial.title.asc.label",
            });

        case "orderMaterial.published.asc":
            return intl.formatMessage({
                id: "orderMaterial.published.asc.label",
            });
        case "orderMaterial.published.desc":
            return intl.formatMessage({
                id: "orderMaterial.published.desc.label",
            });

        case "orderMaterial.visibleUntil.asc":
            return intl.formatMessage({
                id: "orderMaterial.visibleUntil.asc.label",
            });
        case "orderMaterial.visibleUntil.desc":
            return intl.formatMessage({
                id: "orderMaterial.visibleUntil.desc.label",
            });

        case "orderMaterial.quantity.desc":
            return intl.formatMessage({
                id: "orderMaterial.quantity.desc.label",
            });
        case "orderMaterial.quantity.asc":
            return intl.formatMessage({
                id: "orderMaterial.quantity.asc.label",
            });

        case "orderMaterial.reserved.quantity.desc":
            return intl.formatMessage({
                id: "orderMaterial.reserved.quantity.desc.label",
            });
        case "orderMaterial.reserved.quantity.asc":
            return intl.formatMessage({
                id: "orderMaterial.reserved.quantity.asc.label",
            });

        default:
            throw new Error('There is no ordering method called "' + key + '"');
    }
};

const OrderMaterial: FC<Props> = ({
    orderingValues,
    onChange,
    defaultValue,
}) => {
    const options = [];
    const BuildOrderObject = (orderingValue?: string): OrderingValue => {
        switch (orderingValue) {
            case "orderMaterial.title.asc":
                return { title: "ASC" };
            case "orderMaterial.title.desc":
                return { title: "DESC" };
            case "orderMaterial.updated.asc":
                return { updatedAt: "ASC" };
            case "orderMaterial.published.asc":
                return { publishAt: "ASC" };
            case "orderMaterial.published.desc":
                return { publishAt: "DESC" };
            case "orderMaterial.visibleUntil.asc":
                return { visibleUntil: "ASC" };
            case "orderMaterial.visibleUntil.desc":
                return { visibleUntil: "DESC" };
            case "orderMaterial.quantity.desc":
                return { aggregatedQuantity: "DESC" };
            case "orderMaterial.quantity.asc":
                return { aggregatedQuantity: "ASC" };
            case "orderMaterial.reserved.quantity.desc":
                return { aggregatedReservedQuantity: "DESC" };
            case "orderMaterial.reserved.quantity.asc":
                return { aggregatedReservedQuantity: "ASC" };
            default:
                return { updatedAt: "DESC" };
        }
    };

    orderingValues.forEach((value: String) => {
        options.push({ label: GetLabelString(value), value: value });
    });
    if (options.length === 0)
        options.push({
            label: GetLabelString("orderMaterial.updated.desc"),
            value: "orderMaterial.updated.desc",
        });

    return (
        <Select
            defaultValue={defaultValue}
            onChange={(value) => {
                onChange(BuildOrderObject(value));
            }}
            options={options}
            size={"large"}
            dropdownMatchSelectWidth={false}
        />
    );
};

export default OrderMaterial;
